import {
  BetSlipV3Types,
  IBetStrikeResponse
} from "../../../../components/views/betslipNew/betslip.models";

/**
 * This enum contains the sports options/contexts under which the Betslip component can operate.
 */
export enum SportTypes {
  HorseRacing = "HorseRacing",
  Soccer = "Soccer"
}

/**
 * The the current meeting for which you are taking a bet.
 */
export type TCurrentMeeting = {
  date: string;
  id: number;
  name: string;
};

export type TRace = {
  raceNumber: number;
  meetingTime: string;
  horseName: string;
  horseNumber: number;
  jockey: string;
  trainer: string;
  odds: number;
  placeOdds: number;
  firstTimer: boolean;
  selected: boolean;
};

export type THorseRacingBetType = {
  betType: BetSlipV3Types;
  betCount: number;
  finalOdds: number;
  stakeToBet: number;
  enabled: boolean;
  isFixedOdds: boolean;
  legs: any[];
  overLimit: boolean;
  totalBetStake: number;
  betPotentialReturn: number;
};

/** Encompasses all the fields for betslip  */
export type TBetTypes = THorseRacingBetType[];

export type TLimits = {
  single: number;
  double: number;
  treble: number;
  quad: number;
  multiple: number;
};

export type TLimitConfig = {
  firstTimerLimits: TLimits;
  regularLimits: TLimits;
};

export interface IBetslipBetType {
  deductionPercentageUsed: number | null;
  eventBetTypeDetailMapID: number;
  eventDateTime: string;
  eventDetailID: number;
  eventDetailName: string;
  eventDetailNameExtra: string;
  eventDetailNumber: number;
  eventDetailOfferedOdds: number | null;
  eventID: number;
  eventName: string;
  eventNumber: number;
  fK_EventBetTypeMapID: number;
  fK_EventDetailStatusID: number;
  favourite: boolean | null;
  finishingPosition: number | null;
  firstTimer: boolean | null;
}

/**
 * Shape of the data that gets returned from getSpecialBetslip endpoint
 */
export type TBetslipData = {
  bets: IBetslipBetType[];
  spBets: IBetslipBetType[];
  placeBets: IBetslipBetType[];
};

export type TPunterBet = {
  doubleJackpotBet: {
    meetingId: number;
    punterId: number;
    betTotal: number;
  };
};

export type BetslipSportsConfig = {
  increments: number[];
  bets: TBetTypes;
  event: TCurrentMeeting;
  betStrikeResponse: IBetStrikeResponse | null;
  allowBet: boolean;
  totalStake: number;
  totalReturn: number;
  selections: TRace[];
  forcedSP: boolean;
  limits: TLimitConfig;
  betslipData: TBetslipData;
  isFetchingBetslipData: boolean;
  punterBetAmount: number;
  hasFetchedPunterBetAmount: boolean;
  headerSelectOddsToggle: boolean;
  isStrikingBet: boolean;
};

export interface IInitialDataBetslip {
  [SportTypes.HorseRacing]: BetslipSportsConfig;
  [SportTypes.Soccer]: BetslipSportsConfig;
}

/**
 * Controls the increment control that is available in the Betslip for each sports type.
 */
export type TIncrements = {
  [T in SportTypes]: number[];
};

export type IHorseRacingBetTypesArr = {
  [SportTypes.HorseRacing]: TBetTypes[];
  [SportTypes.Soccer]: TBetTypes[];
};

export interface IHorseRacingSelection {
  raceNumber: number;
  horseNumber: number;
  horseName: string;
  odds: number;
  placeOdds: number;
  meetingTime: number | string;
  firstTimer: boolean;
  selected: boolean;
}

export type BetslipRequestData = {
  selection: any;
  punterId?: string | number;
  userName?: string;
  meetingId?: string;
};

export type TUpdateSelectionPayload = {
  index: number;
  isSelected: boolean;
};

export type TUpdateBetStakePayload = {
  index: number;
  stake: number;
};

export type TUpdateAllBetStakesPayload = {
  stake: number;
};

export type TResetBetStakePayload = {
  index: number;
};

export type TSetFixedOddsSingleBetPayload = {
  index: number;
  isFixedOdds: boolean;
};

export type TUpdateBetTypeEnabledPayload = {
  index: number;
  enabled: boolean;
};

export type TSelectAllPayload = {
  isAllSelected: boolean;
};
