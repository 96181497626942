/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import update from "immutability-helper";
import {
  createAsyncThunk,
  PayloadAction,
  createSlice,
  createSelector
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import {
  IBetSlipBet,
  IBetSlipData,
  IBetSlipRace,
  IBetStrikeRetryRequest
} from "../../components/views/betslipNew/betslip.models";
import { BetPlacementPayloadData } from "./betslipSlice";
import Calculations from "../../components/views/betslipNew/helpers/calculations";
import { isAllToBet } from "../../components/views/betslipNew/helpers/betUtils";

const initialState: IBetSlipData = {
  meeting: {
    meetingId: 0,
    meetingName: "",
    meetingDate: ""
  },
  races: [],
  bets: [],
  totalStake: 0,
  totalReturn: 0,
  betStrikeResponse: null,
  allowBet: true,
  forcedSP: false,
  limits: {}
};

export interface IUpdateBetStake {
  index: number;
  stake: number;
}

export interface IUpdateHorseSelection {
  index: number;
  isSelected: boolean;
}

export interface IUpdateBetTypeEnabled {
  index: number;
  isEnabled: boolean;
}

export interface IUpdateBetTypeOddType {
  index: number;
  isFixedOdds: boolean;
}

export interface IUpdateLegEnabled {
  index: number;
  legIndex: number;
  isEnabled: boolean;
}

export interface IUpdatePayoutLimit {
  index: number;
  firstTimer: boolean;
}

const configConst = (state: RootState) => state.config.data;

export const getPayoutLimits: any = createAsyncThunk(
  "getPayoutLimits",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Betslip/GetPayoutLimits`
    );
    return data.data;
  }
);

export const strikeBet: any = createAsyncThunk(
  "strikeBet",
  async (
    { bets, singleBet, isLuckyPick, meetingId }: BetPlacementPayloadData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const betPlaced = await axios.post(
      `${config.puntersApi}/api/Betslip/PlaceBet?meetingId=${meetingId}`,
      { bets, singleBet, isLuckyPick, meetingId }
    );
    return betPlaced;
  }
);

export const retryFailedBets: any = createAsyncThunk(
  "retryFailedBets",
  async (
    { betRequests, isLuckyPick, totalBets, meetingId }: IBetStrikeRetryRequest,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const retriedBets = await axios.post(
      `${config.puntersApi}/api/Betslip/RetryFailedBets?meetingId=${meetingId}`,
      { betRequests, isLuckyPick, totalBets, meetingId }
    );
    return retriedBets;
  }
);

const getOverLimit = (bet: IBetSlipBet, state: IBetSlipData): boolean => {
  const potentialPayout = Calculations.getPotentialPayout(bet);
  const potentialPayoutPerBet = potentialPayout / bet.betCount;
  const betPayout = isAllToBet(bet) ? potentialPayout : potentialPayoutPerBet;

  const hasFirstTimer =
    state.races.filter((race) => race.firstTimer && race.selected).length > 0;

  return Calculations.getLimitReached(
    bet.betType,
    betPayout,
    hasFirstTimer,
    state.limits
  );
};

const validateAllOverlimit = (state: IBetSlipData): IBetSlipData => {
  const updatedBets = state.bets.map((bet: IBetSlipBet): IBetSlipBet => {
    return {
      ...bet,
      overLimit: bet.enabled ? getOverLimit(bet, state) : false
    };
  });

  return { ...state, bets: updatedBets };
};

export const NewBetSlipSlice = createSlice({
  name: "NewBetSlip",
  initialState,
  reducers: {
    setInitialState: (
      state: IBetSlipData,
      action: PayloadAction<IBetSlipData>
    ) => {
      const newState = action.payload;
      const updatedState = validateAllOverlimit(newState);
      return {
        ...updatedState,
        totalReturn: Calculations.getTotalPotentialPayout(updatedState.bets),
        totalStake: Calculations.getTotalStake(updatedState.bets)
      };
    },
    updateBetStake: (
      state: IBetSlipData,
      action: PayloadAction<IUpdateBetStake>
    ) => {
      const newState = update(state, {
        bets: {
          [action.payload.index]: {
            stakeToBet: { $set: action.payload.stake }
          }
        }
      });

      const updateState = update(newState, {
        bets: {
          [action.payload.index]: {
            overLimit: {
              $set: getOverLimit(newState.bets[action.payload.index], newState)
            }
          }
        }
      });

      return {
        ...updateState,
        totalReturn: Calculations.getTotalPotentialPayout(updateState.bets),
        totalStake: Calculations.getTotalStake(updateState.bets)
      };
    },
    setAllowBet: (state: IBetSlipData, action: PayloadAction<boolean>) => {
      const newState = update(state, {
        allowBet: { $set: action.payload }
      });

      return newState;
    },
    updateHorseSelected: (
      state: IBetSlipData,
      action: PayloadAction<IUpdateHorseSelection>
    ) => {
      const newState = update(state, {
        races: {
          [action.payload.index]: {
            selected: { $set: action.payload.isSelected }
          }
        }
      });

      return newState;
    },
    selectAllHorses: (state: IBetSlipData, action: PayloadAction<boolean>) => {
      const newRaces: IBetSlipRace[] = [];

      state.races.forEach((race) => {
        newRaces.push({ ...race, selected: action.payload });
      });

      const newState = update(state, {
        races: { $set: newRaces }
      });

      return newState;
    },
    setAllBetTypeToggle: (
      state: IBetSlipData,
      action: PayloadAction<boolean>
    ) => {
      const newBets: IBetSlipBet[] = [];

      state.bets.forEach((bet) => {
        newBets.push({ ...bet, isFixedOdds: action.payload });
      });

      const newState = update(state, {
        bets: { $set: newBets }
      });

      return {
        ...newState,
        totalReturn: Calculations.getTotalPotentialPayout(newState.bets),
        totalStake: Calculations.getTotalStake(newState.bets)
      };
    },
    updateBetTypeEnabled: (
      state: IBetSlipData,
      action: PayloadAction<IUpdateBetTypeEnabled>
    ) => {
      const newState = update(state, {
        bets: {
          [action.payload.index]: {
            enabled: { $set: action.payload.isEnabled }
          }
        }
      });

      return {
        ...newState,
        totalReturn: Calculations.getTotalPotentialPayout(newState.bets),
        totalStake: Calculations.getTotalStake(newState.bets)
      };
    },
    updateBetTypeOddType: (
      state: IBetSlipData,
      action: PayloadAction<IUpdateBetTypeOddType>
    ) => {
      const newState = update(state, {
        bets: {
          [action.payload.index]: {
            isFixedOdds: { $set: action.payload.isFixedOdds }
          }
        }
      });

      return {
        ...newState,
        totalReturn: Calculations.getTotalPotentialPayout(newState.bets),
        totalStake: Calculations.getTotalStake(newState.bets)
      };
    },
    updateLegEnabled: (
      state: IBetSlipData,
      action: PayloadAction<IUpdateLegEnabled>
    ) => {
      const newState = update(state, {
        bets: {
          [action.payload.index]: {
            legs: {
              [action.payload.legIndex]: {
                enabled: { $set: action.payload.isEnabled }
              }
            }
          }
        }
      });

      if (
        newState.bets[action.payload.index].legs.filter((leg) => leg.enabled)
          .length === 0
      ) {
        const newBetState = update(newState, {
          bets: {
            [action.payload.index]: { enabled: { $set: false } }
          }
        });

        const updatedState = update(newBetState, {
          bets: {
            [action.payload.index]: {
              overLimit: {
                $set: false
              }
            }
          }
        });

        return {
          ...updatedState,
          totalReturn: Calculations.getTotalPotentialPayout(updatedState.bets),
          totalStake: Calculations.getTotalStake(updatedState.bets)
        };
      }

      const updatedState = update(newState, {
        bets: {
          [action.payload.index]: {
            overLimit: {
              $set: getOverLimit(newState.bets[action.payload.index], newState)
            }
          }
        }
      });

      return {
        ...updatedState,
        totalReturn: Calculations.getTotalPotentialPayout(updatedState.bets),
        totalStake: Calculations.getTotalStake(updatedState.bets)
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPayoutLimits.fulfilled, (state, action) => {
      console.log("getPayoutLimits.fulfilled");
      state.limits = action.payload;
    });

    // StrikeBet
    builder.addCase(strikeBet.pending, () => {
      console.log("@newBetSlipSlice strikeBet.pending state");
    });

    builder.addCase(strikeBet.fulfilled, (state, action) => {
      let resolvedData = action.payload.data;

      if (typeof action.payload.data === "object") {
        resolvedData = JSON.stringify(action.payload.data);
      }

      console.log(
        `@newBetSlipSlice strikeBet.fulfilled state: ${resolvedData}`
      );

      state.betStrikeResponse = JSON.parse(
        resolvedData.slice(resolvedData.indexOf("{"))
      );
    });

    builder.addCase(strikeBet.rejected, (state, action) => {
      console.log(
        "@newBetSlipSlice strikeBet.rejected state ,reason = ",
        action.error.message
      );
    });

    // RetryFailedBets
    builder.addCase(retryFailedBets.pending, () => {
      console.log("@newBetSlipSlice retryFailedBets.pending state");
    });

    builder.addCase(retryFailedBets.fulfilled, (state, action) => {
      console.log(
        `@newBetSlipSlice retryFailedBets.fulfilled state: ${JSON.stringify(action.payload.data)}`
      );

      state.betStrikeResponse = JSON.parse(
        action.payload.data.slice(action.payload.data.indexOf("{"))
      );
    });

    builder.addCase(retryFailedBets.rejected, (state, action) => {
      console.log(
        "@newBetSlipSlice retryFailedBets.rejected state ,reason = ",
        action.error.message
      );
    });
  }
});

export const betStrikeResponseSelector = createSelector(
  (state: RootState) => state.betslipNew,
  (data) => {
    return data.betStrikeResponse;
  }
);

export default NewBetSlipSlice.reducer;

export const {
  setInitialState,
  updateBetStake,
  updateHorseSelected,
  selectAllHorses,
  updateBetTypeEnabled,
  updateBetTypeOddType,
  updateLegEnabled,
  setAllowBet,
  setAllBetTypeToggle
} = NewBetSlipSlice.actions;
