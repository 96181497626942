/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import update from "immutability-helper";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import BetslipCalculations from "../utilities/Calculations/HorseRacingCalculations";
import { isAllToBet } from "../../../../components/views/betslipNew/helpers/betUtils";
import {
  BetslipSportsConfig,
  IInitialDataBetslip,
  SportTypes,
  TBetslipData,
  THorseRacingBetType,
  TLimitConfig,
  TSetFixedOddsSingleBetPayload,
  TUpdateBetStakePayload,
  TUpdateBetTypeEnabledPayload,
  TResetBetStakePayload,
  TUpdateAllBetStakesPayload,
  TUpdateSelectionPayload,
  TSelectAllPayload,
  TPunterBet
} from "../types/types";
import BetSlipSliceInitData from "../config/InitialDataBetslipV3";
import * as BetslipThunks from "./BetslipThunks";
import { RootState } from "../../../store";

export const getOverLimit = (
  bet: THorseRacingBetType,
  state: BetslipSportsConfig
): boolean => {
  const potentialPayout = BetslipCalculations.getPotentialPayout(bet);
  const potentialPayoutPerBet = potentialPayout / bet.betCount;
  const betPayout = isAllToBet(bet) ? potentialPayout : potentialPayoutPerBet;

  const hasFirstTimer =
    state.selections.filter(
      (selection) => selection.firstTimer && selection.selected
    ).length > 0;

  return BetslipCalculations.getLimitReached(
    bet.betType,
    betPayout,
    hasFirstTimer,
    state.limits
  );
};

const validateAllOverlimit = (
  state: IInitialDataBetslip[SportTypes.HorseRacing]
): THorseRacingBetType[] => {
  const updatedBets = state.bets.map(
    (bet: THorseRacingBetType): THorseRacingBetType => {
      return {
        ...bet,
        overLimit: bet.enabled ? getOverLimit(bet, state) : false
      };
    }
  );

  return updatedBets;
};

export const BetslipV3Slice = createSlice({
  name: "BetslipV3Slice",
  /**
   * The Value passed to getBetslipInitData would come from the global store. For the current PuntersV2 site it is being defaulted to to Horse Racing.
   */
  initialState: BetSlipSliceInitData[SportTypes.HorseRacing],
  reducers: {
    /**
     * Set the beslip slice initial data
     */
    setBetSlipState: (
      state,
      action: PayloadAction<IInitialDataBetslip[SportTypes.HorseRacing]>
    ) => {
      const newState = action.payload;
      newState.bets = validateAllOverlimit(newState);

      return {
        ...newState,
        totalReturn: BetslipCalculations.getTotalPotentialPayout(newState.bets),
        totalStake: BetslipCalculations.getTotalStake(newState.bets)
      };
    },
    /**
     * Increments the stake for a specific bet at a specific index in the bets[] object
     */
    incrementBetStake: (
      state,
      action: PayloadAction<TUpdateBetStakePayload>
    ) => {
      state.bets[action.payload.index].stakeToBet += action.payload.stake;
      state.bets[action.payload.index].totalBetStake =
        state.bets[action.payload.index].stakeToBet *
        state.bets[action.payload.index].betCount;
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Increments all stakes in the bets[] object
     */
    incrementAllBetStakes: (
      state,
      action: PayloadAction<TUpdateAllBetStakesPayload>
    ) => {
      state.bets = state.bets.map((_) => {
        _.stakeToBet += action.payload.stake;
        return _;
      });
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Updates the stake for a specific bet at a specific index in the bets[] object
     */
    updateBetStake: (state, action: PayloadAction<TUpdateBetStakePayload>) => {
      state.bets[action.payload.index].stakeToBet = action.payload.stake;
      state.bets[action.payload.index].totalBetStake =
        state.bets[action.payload.index].stakeToBet *
        state.bets[action.payload.index].betCount;
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Updates all stakes for the bets[] object
     */
    updateAllBetStakes: (
      state,
      action: PayloadAction<TUpdateAllBetStakesPayload>
    ) => {
      state.bets = state.bets.map((_) => {
        _.stakeToBet = action.payload.stake;
        return _;
      });
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Sets bet stake to 0 for the specifed index of the bets[] object
     */
    removeBetStake: (state, action: PayloadAction<TResetBetStakePayload>) => {
      state.bets[action.payload.index].stakeToBet = 0;
      state.bets[action.payload.index].totalBetStake =
        state.bets[action.payload.index].stakeToBet *
        state.bets[action.payload.index].betCount;
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Updates both the Total Stake and the Potential Payout
     * @param state Current state of the slice.
     */
    updateTotalStakeAndPotentialTotalPayout: (state) => {
      const newBets = validateAllOverlimit(state);
      state.bets = newBets;
      state.totalStake = BetslipCalculations.getTotalStake(newBets);
      state.totalReturn = BetslipCalculations.getTotalPotentialPayout(newBets);
    },
    setAllowBet: (state, action: PayloadAction<boolean>) => {
      state.allowBet = action.payload;
    },
    /**
     * Updates the specific index in teh selections[] object
     */
    updateSelection: (
      state,
      action: PayloadAction<TUpdateSelectionPayload>
    ) => {
      const newState = update(state, {
        selections: {
          [action.payload.index]: {
            selected: { $set: action.payload.isSelected }
          }
        }
      });
      return newState;
    },
    /**
     * Sets the selected key in each of the selections to true. The selections are made before the Betslip modal pops up.
     */
    selectAll: (state, action: PayloadAction<TSelectAllPayload>) => {
      state.selections = state.selections.map((_) => {
        _.selected = action.payload.isAllSelected;
        return _;
      });
    },
    /**
     * Sets the fixedOdds key in each entry of the bets[] object
     */
    setFixedOddsAllBets: (state, action: PayloadAction<boolean>) => {
      state.bets = state.bets.map((_) => {
        _.isFixedOdds = action.payload;
        return _;
      });
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Sets the specific index's fixedOdds key in the bets[] object
     */
    setFixedOddsSingleBet: (
      state,
      action: PayloadAction<TSetFixedOddsSingleBetPayload>
    ) => {
      state.bets[action.payload.index].isFixedOdds = action.payload.isFixedOdds;
      BetslipV3Slice.caseReducers.updateTotalStakeAndPotentialTotalPayout(
        state
      );
    },
    /**
     * Updates the enabled key for the specific entry of the bets[] object
     */
    updateBetTypeEnabled: (
      state,
      action: PayloadAction<TUpdateBetTypeEnabledPayload>
    ) => {
      state.bets[action.payload.index].enabled = action.payload.enabled;
    },
    setHeaderSelectOddsToggle: (state, action: PayloadAction<boolean>) => {
      state.headerSelectOddsToggle = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      BetslipThunks.getPayoutLimits.fulfilled,
      (state, action: PayloadAction<TLimitConfig>) => {
        state.limits = action.payload;
      }
    );

    builder.addCase(BetslipThunks.strikeBetV3.pending, (state) => {
      state.isStrikingBet = true;
    });

    builder.addCase(
      BetslipThunks.strikeBetV3.fulfilled,
      (state, action: PayloadAction<{ data: string }>) => {
        state.isStrikingBet = false;
        state.betStrikeResponse =
          typeof action.payload.data === "string"
            ? JSON.parse(
                action.payload.data.slice(action.payload.data.indexOf("{"))
              )
            : action.payload.data;
      }
    );

    builder.addCase(BetslipThunks.strikeBetV3.rejected, (state) => {
      state.isStrikingBet = false;
    });

    builder.addCase(BetslipThunks.retryFailedBets.pending, (state) => {
      console.log("retry pending");
      state.betStrikeResponse = null;
      state.isStrikingBet = true;
    });

    builder.addCase(
      BetslipThunks.retryFailedBets.fulfilled,
      (state, action: PayloadAction<{ data: string }>) => {
        state.isStrikingBet = false;
        console.log("action.payload.data", action.payload.data.indexOf("{"));
        state.betStrikeResponse = JSON.parse(
          action.payload.data.slice(action.payload.data.indexOf("{"))
        );
      }
    );

    builder.addCase(BetslipThunks.retryFailedBets.rejected, (state) => {
      state.isStrikingBet = false;
    });

    builder.addCase(BetslipThunks.getBetslipDataV3.pending, (state) => {
      state.isFetchingBetslipData = true;
    });

    builder.addCase(
      BetslipThunks.getBetslipDataV3.fulfilled,
      (state, action: PayloadAction<TBetslipData>) => {
        state.betslipData = action.payload;
        state.isFetchingBetslipData = false;
      }
    );

    builder.addCase(BetslipThunks.getBetslipDataV3.rejected, (state) => {
      state.isFetchingBetslipData = false;
    });

    builder.addCase(BetslipThunks.getPunterBet.pending, (state) => {
      state.hasFetchedPunterBetAmount = false;
    });

    builder.addCase(
      BetslipThunks.getPunterBet.fulfilled,
      (state, action: PayloadAction<TPunterBet>) => {
        state.punterBetAmount = action.payload.doubleJackpotBet.betTotal;
        state.hasFetchedPunterBetAmount = true;
      }
    );
  }
});

export const betStrikeResponseV3Selector = createSelector(
  (state: RootState) => state.betslipv3,
  (data) => {
    return data.betStrikeResponse;
  }
);

export default BetslipV3Slice.reducer;

export const {
  setBetSlipState,
  incrementBetStake,
  incrementAllBetStakes,
  updateBetStake,
  updateAllBetStakes,
  removeBetStake,
  updateTotalStakeAndPotentialTotalPayout,
  setAllowBet,
  updateSelection,
  selectAll,
  setFixedOddsAllBets,
  setFixedOddsSingleBet,
  updateBetTypeEnabled,
  setHeaderSelectOddsToggle
} = BetslipV3Slice.actions;
