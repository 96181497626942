import { THorseRacingBetType } from "../types/types";
import { BetSlipV3Types } from "../../../../components/views/betslipNew/betslip.models";

class Betslip {
  /**
   *
   * @returns An array of Horse Racing bet types
   */
  public static readonly BuildHorseRacingBetTypes =
    (): THorseRacingBetType[] => {
      const betTypesArr = [];
      for (const betType of Object.values(BetSlipV3Types)) {
        betTypesArr.push({
          betType,
          betCount: 0,
          finalOdds: 0,
          stakeToBet: 1,
          enabled: true,
          isFixedOdds: true,
          legs: [],
          overLimit: false,
          totalBetStake: 0,
          betPotentialReturn: 0
        });
      }
      return betTypesArr;
    };

  /**
   *
   * @returns An array of Soccer bet types. The type is not correct as we don't know what it will look like just yet but it has been typed the way it is
   * to test concepts and not actual correct implementation. This was done because the same betslip slice will be used for the upcoming Soccer Punters project.
   */
  public static readonly BuildSoccerBetTypes = (): THorseRacingBetType[] => {
    const betTypesArr = [];
    for (const betType of Object.values(BetSlipV3Types)) {
      betTypesArr.push({
        betType,
        betCount: 0,
        finalOdds: 0,
        stakeToBet: 10,
        enabled: true,
        isFixedOdds: true,
        legs: [],
        overLimit: false,
        totalBetStake: 0,
        betPotentialReturn: 0
      });
    }
    return betTypesArr;
  };
}

export default Betslip;
