import {
  BetSlipV3Types,
  IBetSlipLeg
} from "../../../../../components/views/betslipNew/betslip.models";
import { isAllToBet } from "../../../../../components/views/betslipNew/helpers/betUtils";
import {
  TBetTypes,
  THorseRacingBetType,
  TLimitConfig
} from "../../types/types";
/**
 * A class that handles the calculations for the Betslip when the sports choice
 * is 'HorseRacing'. The valid values come from the SportTypes enum.
 */
export default class HorseRacingCalculations {
  static readonly getLimitReached = (
    betType: BetSlipV3Types,
    payoutPerBet: number,
    firstTimer: boolean,
    limits: TLimitConfig
  ): boolean => {
    if (Object.keys(limits).length > 0) {
      const payoutLimit = firstTimer
        ? limits.firstTimerLimits
        : limits.regularLimits;
      switch (betType) {
        case BetSlipV3Types.SingleWin:
        case BetSlipV3Types.SinglePlace:
          return payoutLimit.single < payoutPerBet;

        case BetSlipV3Types.WinDoubles:
          return payoutLimit.double < payoutPerBet;

        case BetSlipV3Types.WinTrebles:
          return payoutLimit.treble < payoutPerBet;

        case BetSlipV3Types.WinQuads:
          return payoutLimit.quad < payoutPerBet;

        case BetSlipV3Types.AllToPlace:
        case BetSlipV3Types.AllToCome:
          return payoutLimit.multiple < payoutPerBet;

        default:
          return true;
      }
    }
    return false;
  };

  static readonly getCalculatedOdds = (oddsList: number[]): number => {
    return (
      oddsList
        .map((odd) => odd + 1)
        .reduce((firstOdd, secondOdd) => firstOdd * secondOdd) - 1
    );
  };

  static readonly getOddsFromLegs = (legs: IBetSlipLeg[], isPlace: boolean) => {
    const enabledLegs = legs.filter((leg) => leg.enabled);
    const enabledRaces = enabledLegs.map((leg) => leg.race);
    const finalOdds = enabledRaces.map((item) =>
      isPlace ? item.placeOdds : item.odds
    );

    return finalOdds;
  };

  static readonly getCalculatedOddsSingle = (
    oddsList: number[],
    hasSelect: boolean
  ) =>
    hasSelect
      ? oddsList
          .map((odd) => odd)
          .reduce((firstOdd, secondOdd) => firstOdd + secondOdd, 0)
      : 0;

  static readonly allCombinationsUtil = (
    arr: any[],
    dataArr: any[],
    start: number,
    end: number,
    index: number,
    r: number,
    store: any[]
  ) => {
    let temp = [];

    if (index === r) {
      temp = [];
      for (let j = 0; j < r; j++) {
        temp.push(dataArr[j]);
      }
      store.push(temp);
    }
    const tempDataArr = [...dataArr];
    for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
      tempDataArr[index] = arr[i];
      this.allCombinationsUtil(
        arr,
        tempDataArr,
        i + 1,
        end,
        index + 1,
        r,
        store
      );
    }
  };

  static readonly getMultiesOdds = (oddsList: number[], factorial: number) => {
    const currentCombinations: number[] = [];
    const end = oddsList.length - 1;
    const start: number = 0;
    const index: number = 0;
    const store: number[][] = [];

    this.allCombinationsUtil(
      oddsList,
      currentCombinations,
      start,
      end,
      index,
      factorial,
      store
    );

    let totalOdds = 0;

    /* eslint-disable no-restricted-syntax */
    for (const val of store) {
      const odds = HorseRacingCalculations.getCalculatedOdds(val);
      totalOdds += odds;
    }
    /* eslint-enable */
    return totalOdds;
  };

  static readonly getOddsFormultiples = (arrinput: number[]) => {
    let product = 1;
    arrinput
      .map((x) => x + 1)
      .forEach((item) => {
        product *= item;
      });

    return product;
  };

  static readonly combinations = (legs: number, div: number): number =>
    this.factorialDivision(legs, legs - div) / this.factorial(div);

  static getTotalStake(bets: THorseRacingBetType[]): number {
    const totalStake = bets
      .filter(
        (bet) =>
          bet.enabled &&
          (!bet.overLimit || !bet.isFixedOdds) &&
          bet.betCount > 0
      )
      .map((bet) => this.getTotalStakeForBetType(bet))
      .reduce((stake1, stake2) => stake1 + stake2, 0);

    return Number.isNaN(totalStake) ? 0 : totalStake;
  }

  static getTotalPotentialPayout(bets: TBetTypes): number {
    const totalPotentialPayout = bets
      .filter((bet) => bet.enabled && (!bet.overLimit || !bet.isFixedOdds))
      .map((bet) => this.getPotentialReturn(bet))
      .reduce((stake1, stake2) => stake1 + stake2, 0);

    return Number.isNaN(totalPotentialPayout) ? 0 : totalPotentialPayout;
  }

  static getPotentialPayout(bet: THorseRacingBetType): number {
    if (bet.legs.length > 0 && isAllToBet(bet)) {
      const multiOdds = HorseRacingCalculations.getCalculatedOdds(
        HorseRacingCalculations.getOddsFromLegs(
          bet.legs,
          bet.betType === BetSlipV3Types.AllToPlace
        )
      );

      return multiOdds * bet.stakeToBet;
    }
    return bet.finalOdds * bet.stakeToBet;
  }

  static getPotentialReturn(bet: THorseRacingBetType): number {
    const potentialPayout = this.getPotentialPayout(bet);
    if (bet.legs.length > 0 && isAllToBet(bet)) {
      return potentialPayout + bet.stakeToBet;
    }
    return potentialPayout + bet.betCount * bet.stakeToBet;
  }

  static getTotalStakeForBetType(
    bet: THorseRacingBetType,
    isAnyHorseSelected: boolean = true
  ): number {
    if (isAllToBet(bet)) {
      return isAnyHorseSelected ? bet.stakeToBet : 0;
    }
    const total = bet.stakeToBet * bet.betCount;
    return total;
  }

  private static factorialDivision(
    topFactorial: number,
    divisorFactorial: number
  ): number {
    let result = 1;
    for (let i = topFactorial; i > divisorFactorial; i--) result *= i;
    return result;
  }

  private static factorial(i: number): number {
    if (i <= 1) return 1;
    return i * this.factorial(i - 1);
  }
}
