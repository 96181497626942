/**
 * A file that contains all the thunks required for BetslipV3 slice.
 */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { BetPlacementPayloadData } from "../../betslipSlice";
import {
  IBetStrikeRetryRequest,
  IPunterBetRequest
} from "../../../../components/views/betslipNew/betslip.models";
import {
  BetslipRequestData,
  TBetslipData,
  TLimitConfig,
  TPunterBet
} from "../types/types";

const configConst = (state: RootState) => state.config.data;

export const getPayoutLimits = createAsyncThunk(
  "getPayoutLimits",
  async (_, { getState }): Promise<TLimitConfig> => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Betslip/GetPayoutLimits`
    );
    return data.data;
  }
);

export const strikeBetV3: any = createAsyncThunk(
  "strikeBetV3",
  async (
    { bets, singleBet, isLuckyPick, meetingId }: BetPlacementPayloadData,
    { getState }
  ): Promise<{ data: string }> => {
    const config = configConst(getState() as RootState);

    const betPlaced = await axios.post(
      `${config.puntersApi}/api/Betslip/PlaceBet?meetingId=${meetingId}`,
      { bets, singleBet, isLuckyPick, meetingId }
    );
    return betPlaced;
  }
);

export const retryFailedBets: any = createAsyncThunk(
  "retryFailedBets",
  async (
    { betRequests, isLuckyPick, totalBets, meetingId }: IBetStrikeRetryRequest,
    { getState }
  ): Promise<{ data: string }> => {
    const config = configConst(getState() as RootState);

    const retriedBets = await axios.post(
      `${config.puntersApi}/api/Betslip/RetryFailedBets?meetingId=${meetingId}`,
      { betRequests, isLuckyPick, totalBets }
    );
    return retriedBets;
  }
);

export const getBetslipDataV3: any = createAsyncThunk(
  "getBetslipDataV3",
  async (
    { selection, punterId, meetingId }: BetslipRequestData,
    { getState }
  ): Promise<TBetslipData> => {
    const config = configConst(getState() as RootState);

    const specialBetslip = await axios.post(
      `${config.puntersApi}/api/betSlip/getSpecialBetslip/${punterId}/${meetingId}`,
      selection
    );

    return specialBetslip.data;
  }
);

export const getPunterBet: any = createAsyncThunk(
  "getPunterBet",
  async (
    { punterId, meetingId }: IPunterBetRequest,
    { getState }
  ): Promise<TPunterBet> => {
    const config = configConst(getState() as RootState);

    const punterBet = await axios.get(
      `${config.puntersApi}/api/Betslip/GetPunterBet/${punterId}/${meetingId}`
    );

    return punterBet.data;
  }
);
