import { THorseRacingBetType } from "../../../../store/features/BetslipV3/types/types";
import { BetSlipV3Types, BetType, IBetSlipBet } from "../betslip.models";

export const isAllToBet = (bet: IBetSlipBet | THorseRacingBetType): boolean => {
  return (
    bet.betType === BetType.AllToCome || bet.betType === BetType.AllToPlace
  );
};

export const isSingleBet = (betType: BetType | BetSlipV3Types): boolean =>
  betType === BetType.SinglePlace || betType === BetType.SingleWin;

export const isPlaceBet = (bet: IBetSlipBet | THorseRacingBetType): boolean => {
  return (
    bet.betType === BetType.SinglePlace || bet.betType === BetType.AllToPlace
  );
};

export const isGlobalToggleDisabled = (
  bets: THorseRacingBetType[]
): boolean => {
  return bets
    .filter((bet) => !isPlaceBet(bet))
    .every((bet) => bet.stakeToBet === 0 || bet.betCount === 0);
};
