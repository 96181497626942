/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import {
  createSlice,
  createAsyncThunk,
  createSelector
} from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../store";
import { RACE_SELECTION_CACHE } from "../../constants";
import sortRacesWithSAPriority from "../../utils/raceCourseUtils";

export interface IMeetingPuntersJacktopSelection {
  raceNumber: number;
  selection: number;
  totalSelections: number;
}

export interface IMeetingMetaData {
  meetingId: number | string;
  meetingDate: number | string;
  meetingStatusId: number | string;
  raceCourseName: string;
  raceTrackAssetId: number | string;
}

export interface IRaceRunner {
  raceNumber: number | string;
  saddle: number | string;
  horseName: string;
  hollywoodOdds: number | string;
  runnerStatus: number | string;
  totalSelected: number | string;
  finishingPosition: number | string;
  horsePoints: number | string;
  favourite: boolean;
  jockey: string;
  trainer: string;
}

export interface IMeetingRace {
  raceTrackAssetId: number | string;
  raceDate: number | string;
  raceTime: number | string;
  raceNumber: number | string;
  resulted: boolean;
  favoriteHorse: string;
  runners: IRaceRunner[];
  statusId: number;
}

interface IMeetingJackpot {
  jackpotFigure: number | string;
  raceRun: number | string;
  winners: number | string;
}

export interface IMeetingProgressive {
  races: number | string;
  winner: number | string;
  players: number | string;
}

export interface IMeeting {
  meetingId: number | string;
  raceTrackAssetId: number | string;
  meetingDate: string;
  races: IMeetingRace[];
}

export interface IMeetingSelections {
  horseName: string;
  points: number;
  raceNumber: number;
  saddle: number;
}

export interface IMeetingSelection {
  meetingId: string;
  punterId: number;
  selections: IMeetingSelections[];
  userId: string | null;
}

export interface IProgressiveWinner {
  winners?: number;
  raceNumber?: number;
}

export interface IProgressiveWinners {
  progressiveWinner: IProgressiveWinner[];
}

const configConst = (state: RootState) => state.config.data;

export const getProgressiveWinners: any = createAsyncThunk(
  "getProgressiveWinners",
  async (meetingId: number, { getState }) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Leaderboard/getProgressiveWinners/${meetingId}`
    );
    return data.data;
  }
);

export const getAllMeetings: any = createAsyncThunk(
  "getAllMeetings",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Meeting/GetAllMeetings`
    );
    return data.data;
  }
);

/**
 * get all meetings basic metadata info
 */
export const getUpcomingMeetings: any = createAsyncThunk(
  "getUpcomingMeetings",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Meeting/GetUpcomingRaceMeetings`
    );

    return data.data;
  }
);

interface IMeetingData {
  punterId: string | number;
  meetingId: string | number;
}
// punter id - 653955
/**
 * get complete meeting data for a meeting id
 */
export const getMeetingData: any = createAsyncThunk(
  "getMeetingData",
  async ({ punterId, meetingId }: IMeetingData, { getState }) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Meeting/GetMeetingRaces/${
        punterId || 0
      }/${meetingId}`
    );

    return data.data;
  }
);

interface JackpotProgressiveQueryData {
  meetingId: string | number;
}

export const getMeetingJackpot: any = createAsyncThunk(
  "getMeetingJackpot",
  async ({ meetingId }: JackpotProgressiveQueryData, { getState }) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Jackpot/jackpotForMeeting/${meetingId}`
    );

    return data.data;
  }
);

export const getMeetingProgressive: any = createAsyncThunk(
  "getMeetingProgressive",
  async ({ meetingId }: JackpotProgressiveQueryData, { getState }) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Leaderboard/getProgressiveMeetings/${meetingId}`
    );
    return data.data;
  }
);

export interface ISavePunterSelectionReqData {
  meetingId: number;
  punterId: number;
  raceNumber: number;
  selection: number;
  horseName: string;
  isValid: boolean;
}

export const savePunterSelection: any = createAsyncThunk(
  "savePunterSelection",
  async (selected: ISavePunterSelectionReqData[], { getState }) => {
    const config = configConst(getState() as RootState);

    const { punterId, meetingId } = selected[0];
    let refreshedMeetingData;

    const save = await axios.post(
      `${config.puntersApi}/api/Selection/SavePunterSelections`,
      selected
    );

    const { responseMessage } = save.data;

    const wasSavingASuccess = responseMessage === "Success";

    if (wasSavingASuccess) {
      refreshedMeetingData = await axios.get(
        `${config.puntersApi}/api/Meeting/GetMeetingRaces/${punterId}/${meetingId}`
      );
      localStorage.removeItem(meetingId + RACE_SELECTION_CACHE);
    }

    return {
      wasSaved: wasSavingASuccess,
      newMeetingData: refreshedMeetingData?.data
    };
  }
);

export const getAllNotifications: any = createAsyncThunk(
  "getAllNotifications",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/leaderboard/GetNotifications`
    );
    return data.data;
  }
);

export const getJackpotSelections: any = createAsyncThunk(
  "getJackpotSelections",
  async (meetingId: number, { getState }) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Meeting/GetJackpotSelections/${meetingId}`
    );

    return { data: data.data, meetingId };
  }
);

interface IMeetings {
  allMeetingsMetaData: IMeetingMetaData[] | null;
  meetingsMetaData: IMeetingMetaData[] | null;
  meetings: Record<string, IMeeting>;
  abandonedMeetings: Record<string, IMeeting> | null;
  jackpots: Record<string, IMeetingJackpot>;
  progressives: Record<string, IMeetingProgressive>;
  progressiveWinners: IProgressiveWinners;
  currentSelectedMeetingId: number | string;
  selections: Record<string | number, IMeetingSelections[]> | null;
  error: string | null;
  selectionSavedJustNow: boolean;
  abandonedList: number[];
  postponedList: number[];
  notifications: any[] | null;
  meetingsClosedMidway: number[];
  tempUnsavedSelections: ISavePunterSelectionReqData[] | null;
  jackpotSelections: {
    [meetingId: number]: {
      [raceNumber: string]: IMeetingPuntersJacktopSelection[];
    };
  };
}

const initialState: IMeetings = {
  allMeetingsMetaData: null,
  meetingsMetaData: null,
  meetings: {},
  abandonedMeetings: null,
  jackpots: {},
  progressives: {},
  progressiveWinners: { progressiveWinner: [] },
  currentSelectedMeetingId: 0,
  selections: null,
  error: null,
  selectionSavedJustNow: false,
  abandonedList: [],
  postponedList: [],
  notifications: [],
  meetingsClosedMidway: [],
  tempUnsavedSelections: null,
  jackpotSelections: {}
};

const MeetingsSlice = createSlice({
  name: "Meetings",
  initialState,
  reducers: {
    setCurrentMeetingId: (state, action) => {
      state.currentSelectedMeetingId =
        action.payload >= 0
          ? action.payload
          : state.meetingsMetaData[0]?.meetingId;
    },
    setSelectionSaved: (state, action) => {
      state.selectionSavedJustNow = action.payload;
    },
    clearAllSelections: (state) => {
      state.selections = {};
    },
    addToMeetingsClosedMidwayList: (state, action) => {
      if (!state.meetingsClosedMidway.includes(action.payload))
        state.meetingsClosedMidway.push(action.payload);
    },
    updateTempSelections: (state, action) => {
      state.tempUnsavedSelections = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMeetings.pending, () => {
      console.log("getAllMeetings.pending state");
    });

    builder.addCase(getAllMeetings.fulfilled, (state, action) => {
      state.allMeetingsMetaData = action.payload.allMeeting;

      console.log("getAllMeetings.fulfilled state");
    });
    builder.addCase(getAllMeetings.rejected, (state, action) => {
      console.log(
        "getAllMeetings.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(getUpcomingMeetings.pending, () => {
      console.log("getUpcomingMeetings.pending state");
    });

    builder.addCase(getUpcomingMeetings.fulfilled, (state, action) => {
      state.meetingsMetaData = sortRacesWithSAPriority(
        action.payload.upcomingMeeting
      );

      if (action.payload.upcomingMeeting.length > 0) {
        action.payload.upcomingMeeting.forEach((item: IMeetingMetaData) => {
          if (item.meetingStatusId === 4)
            state.abandonedList.push(Number(item.meetingId));
          state.abandonedMeetings = {};
          if (item.meetingStatusId === 5)
            state.postponedList.push(Number(item.meetingId));
        });
      }

      console.log("getUpcomingMeetings.fulfilled state");
    });
    builder.addCase(getUpcomingMeetings.rejected, (state, action) => {
      state.error = action.error.message;

      console.log(
        "getUpcomingMeetings.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(getMeetingData.pending, (state) => {
      console.log(" getMeetingData.pending ");
      state.selectionSavedJustNow = false;
      state.error = null;
    });
    builder.addCase(getMeetingData.fulfilled, (state, action) => {
      state.selectionSavedJustNow = false;
      const { meetings, selection, responseMessage } = action.payload;

      console.log(
        `getMeetingData responseMessage ${responseMessage},${meetings.meetingId}`
      );
      if (
        responseMessage === "Success" ||
        responseMessage === "No available selections at this time"
      ) {
        state.meetings[meetings.meetingId] = meetings;

        if (!state.selections) state.selections = {};

        // the below login is handle a race condition whereby selections was coming empty immedietly after a non empty value.
        if (
          !state.selections[meetings.meetingId] ||
          state.selections[meetings.meetingId]?.length <= 0
        ) {
          state.selections[meetings.meetingId] = selection?.selections;
        }
      } else {
        state.error = "Meeting data is not available at the moment.";
      }

      if (state.abandonedList.includes(meetings.meetingId)) {
        state.abandonedMeetings![meetings.meetingId] = meetings;
      }

      console.log("getMeetingData.fulfilled state");
    });

    builder.addCase(getMeetingData.rejected, (state, action) => {
      console.log(
        "getMeetingData.rejected state ,reason = ",
        action.error.message
      );
    });
    builder.addCase(getMeetingJackpot.fulfilled, (state, action) => {
      state.jackpots[action.meta.arg.meetingId] = action.payload;
      console.log("getMeetingJackpot.fulfilled state");
    });
    builder.addCase(getMeetingJackpot.rejected, (state, action) => {
      console.log(
        "getMeetingJackpot.rejected state ,reason = ",
        action.error.message
      );
    });
    builder.addCase(getMeetingProgressive.fulfilled, (state, action) => {
      state.progressives[action.meta.arg.meetingId] =
        action.payload.progressiveValue;

      console.log("getMeetingProgressive.fulfilled state");
    });

    builder.addCase(getProgressiveWinners.fulfilled, (state, action) => {
      state.progressiveWinners = action.payload;
      console.log("getProgressiveWinners.fulfilled state");
    });
    builder.addCase(getProgressiveWinners.rejected, (state, action) => {
      console.log(
        "getProgressiveWinners.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(getMeetingProgressive.rejected, (state, action) => {
      console.log(
        "getMeetingProgressive.rejected state ,reason = ",
        action.error.message
      );
    });
    // on submitting selection

    builder.addCase(savePunterSelection.pending, (state) => {
      state.selectionSavedJustNow = false;
      state.tempUnsavedSelections = null;
      console.log("savePunterSelection.pending ");
    });
    builder.addCase(savePunterSelection.fulfilled, (state, action) => {
      const { wasSaved, newMeetingData } = action.payload;

      state.selectionSavedJustNow = wasSaved;

      if (newMeetingData) {
        if (!state.selections) state.selections = {};
        state.selections[newMeetingData.selection.meetingId] =
          newMeetingData.selection.selections;
      }

      console.log("savePunterSelection.fulfilled state");
    });

    builder.addCase(savePunterSelection.rejected, (state, action) => {
      state.selectionSavedJustNow = false;

      console.log(
        "savePunterSelection.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload?.notififcations;
      console.log("getAllNotifications.fulfilled state");
    });

    builder.addCase(getJackpotSelections.fulfilled, (state, action) => {
      const { data, meetingId } = action.payload;

      state.jackpotSelections[meetingId] =
        data.formattedJackpotSelections ?? {};

      console.log("getJackpotSelections.fulfilled state");
    });

    builder.addCase(getJackpotSelections.rejected, (state, action) => {
      console.log(
        "getJackpotSelections.rejected state ,reason = ",
        action.error.message
      );
    });
  }
});

export const CurrentraceCourseNameSelector = createSelector(
  (state: RootState) => state.meetings,
  (data) =>
    data.meetingsMetaData?.find(
      (item) => item.meetingId === data.currentSelectedMeetingId
    )?.raceCourseName
);

export const {
  setCurrentMeetingId,
  setSelectionSaved,
  clearAllSelections,
  addToMeetingsClosedMidwayList,
  updateTempSelections
} = MeetingsSlice.actions;
export default MeetingsSlice.reducer;
