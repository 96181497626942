/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const configConst = (state: RootState) => state.config.data;

export interface ITestProfanityRequest {
  inputToTest: string;
}

interface IGeneral {
  responseType: number;
  responseMessage: string;
}

const initialState: IGeneral = {
  responseType: 0,
  responseMessage: ""
};

/**
 * Returns whether alias contains profanity or not
 */
export const TestProfanity: any = createAsyncThunk(
  "TestProfanity",
  async ({ inputToTest }: ITestProfanityRequest, { getState }) => {
    const config = configConst(getState() as RootState);

    const response = await axios.get(
      `${config.puntersApi}/api/General/ValidityTest/${inputToTest}`
    );

    return response.data;
  }
);

const GeneralSlice = createSlice({
  name: "general",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(TestProfanity.fulfilled, (state, action) => {
      state.responseType = action.payload.responseType;
      state.responseMessage = action.payload.responseMessage;
    });
  }
});

export default GeneralSlice.reducer;
