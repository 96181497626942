/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import {
  createSlice,
  createAsyncThunk,
  createSelector
} from "@reduxjs/toolkit";
import axios from "axios";

import { clearCookie, getCookie } from "../../utils/cookieUtils";
import type { RootState } from "../store";
import type { IConfig } from "./configSlice";
import { getConfig } from "../../utils/configUtils";

interface IUserData {
  basic: any;
  account: any;
  accessToken: any;
  user: any;
  competition: any;
}
export interface IClientBalance {
  balanceAvailable: number;
  balancePending: number;
  bonusBalance: number;
}

interface ICompetitionMeeting {
  date: string;
  id: string;
  raceCourseName: string;
  tabGoldRef: string;
}

interface IcompetitionData {
  endDate: string;
  futureMeetingId: number;
  id: string;
  meetings: ICompetitionMeeting[];
  mostRecentMeetingId: number;
  startDate: string;
  upcomingMeetingId: string;
}
export interface IUser {
  hasCheckedLoggedIn: boolean;
  isLoggedIn: boolean;
  isHWLoginSuccess: boolean;
  isPCLoginSuccess: boolean;
  responseMessage: string;
  data: IUserData | null;
  isPending: boolean;
  balance: IClientBalance | null;
  error: string;
  userName: string;
  userId: string;
  punterId: string;
  aliasRequired: boolean;
  errorMessage: string;
  competitionData: IcompetitionData | null;
  legacyAccessToken: string | null;
  isUserInternal: boolean;
}
const initialState: IUser = {
  hasCheckedLoggedIn: false,
  isLoggedIn: false,
  isHWLoginSuccess: false,
  isPCLoginSuccess: false,
  responseMessage: "",
  errorMessage: "",
  data: null,
  isPending: false,
  balance: null,
  error: "",
  userName: "",
  userId: "",
  punterId: "",
  aliasRequired: false,
  competitionData: null,
  legacyAccessToken: null,
  isUserInternal: false
};

interface LoginDataType {
  userName: string;
  password: string;
}

const configConst = (state: RootState) => state.config.data;

export const login: any = createAsyncThunk(
  "login",
  async ({ userName, password }: LoginDataType, { getState }) => {
    const config = configConst(getState() as RootState);

    const hwLoginResult = await axios.post(
      `${config.puntersApi}/api/punters/login`,
      { username: userName, password }
    );

    if (hwLoginResult.data.responseMessage !== "Success")
      return { hwLoggedInData: hwLoginResult.data };

    const puntersLogin = await axios.get(
      `${config.puntersApi}/api/Login/GetPunterChallengeLogin/${userName}/Hollywoodbets`
    );

    const clientBalance = await axios.post(
      `${config.puntersApi}/api/Betslip/ClientBalance/${userName}`
    );

    return {
      hwLoggedInData: hwLoginResult.data,
      puntersLoggedInData: puntersLogin.data,
      balance: clientBalance.data
    };
  }
);

export const getClientBalance: any = createAsyncThunk(
  "getClientBalance",
  async ({ userName }: { userName: string }) => {
    const config = getConfig();

    const clientBalance = await axios.post(
      `${config.puntersApi}/api/Betslip/ClientBalance/${userName}`
    );

    return clientBalance.data;
  }
);

export const checkAliasAvailability: any = ({
  alias,
  callback,
  config
}: {
  alias: string;
  callback: (res: any) => void;
  config: IConfig;
}) => {
  axios
    .get(`${config.puntersApi}/api/Login/CheckPunterAlias/${alias}`)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error.status === 500) callback(undefined);
      console.error(error);
    });
};

export const submitAlias: any = createAsyncThunk(
  "submitAlias",
  async ({
    alias,
    loginType,
    userName
  }: {
    alias: string;
    loginType: string;
    userName: string;
    callback: (res: any) => void;
  }) => {
    const config = getConfig();

    const aliasSubmitted = await axios.post(
      `${config.puntersApi}/api/Login/SavePunterAlias`,
      { userAlias: alias, loginType, username: userName }
    );
    const clientBalance = await axios.post(
      `${config.puntersApi}/api/Betslip/ClientBalance/${userName}`
    );

    return { alias: aliasSubmitted, balance: clientBalance.data };
  }
);

export const getLegacyAccessToken: any = createAsyncThunk(
  "getLegacyAccessToken",
  async ({ ssoAccessToken }: { ssoAccessToken: string }) => {
    const config = getConfig();

    const options = {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${ssoAccessToken}`
      }
    };
    const legacyAccessToken = await axios.post(
      `${config.legacyTokenIssuerApi}/issue`,
      {},
      options
    );

    return { legacyAccessToken };
  }
);

export const puntersChallengeLogin: any = createAsyncThunk(
  "puntersChallengeLogin",
  async ({ userName }: { userName: string }) => {
    const config = getConfig();

    const puntersLogin = await axios.get(
      `${config.puntersApi}/api/Login/GetPunterChallengeLogin/${userName}/Hollywoodbets`
    );

    const clientBalance = await axios.post(
      `${config.puntersApi}/api/Betslip/ClientBalance/${userName}`
    );

    return {
      puntersLoggedInData: puntersLogin.data,
      balance: clientBalance.data
    };
  }
);

export const attemptPersistedLogin: any = createAsyncThunk(
  "attemptPersistedLogin",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);

    const LoggedinCookie = getCookie("Loggedin");
    const savedPCdata = localStorage.getItem("rememberpc");

    if (LoggedinCookie && savedPCdata) {
      const decodedPCData = JSON.parse(decodeURIComponent(savedPCdata));
      const puntersLogin = await axios.get(
        `${config.puntersApi}/api/Login/GetPunterChallengeLogin/${decodedPCData.user.username}/Hollywoodbets`
      );

      const clientBalance = await axios.post(
        `${config.puntersApi}/api/Betslip/ClientBalance/${decodedPCData.user.username}`
      );

      return {
        puntersLoggedInData: puntersLogin.data,
        balance: clientBalance.data
      };
    }

    return null;
  }
);

const onPuntersLogin = (
  state: IUser,
  puntersLoggedInData: any,
  toPersist: boolean,
  balance: any
) => {
  if (puntersLoggedInData.user) {
    const { id, punterId, username } = puntersLoggedInData.user;

    state.data = {
      basic: null,
      account: null,
      accessToken: null,
      user: null,
      competition: null
    };
    state.data.user = puntersLoggedInData.user;
    state.data.competition = puntersLoggedInData.competition;
    state.userId = id;
    state.userName = username;
    state.punterId = punterId;
    state.isPCLoginSuccess = true;
    state.isLoggedIn = state.isPCLoginSuccess;
    state.competitionData = puntersLoggedInData.competition;
    state.aliasRequired = false;
    state.isPending = false;
    state.isUserInternal = puntersLoggedInData.user.empStatusId === 1;

    if (balance) state.balance = balance.clientBalances?.responseObject;

    if (toPersist) {
      const toSavepc = JSON.stringify(puntersLoggedInData);
      localStorage.setItem("rememberpc", toSavepc);
    }
  } else if (puntersLoggedInData.responseMessage === "Alias Required") {
    state.aliasRequired = true;
  }

  clearCookie("isInLoginprocess");
};

const UserSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.data = null;
      state.punterId = "";
      clearCookie("Loggedin");
      clearCookie("token");
      localStorage.removeItem("rememberpc");
      localStorage.clear();
    },

    setUserName: (state, action) => {
      state.userName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientBalance.fulfilled, (state, action) => {
      const { clientBalances } = action.payload;

      state.balance = clientBalances.responseObject;
    });

    builder.addCase(submitAlias.pending, (state) => {
      state.isPending = true;
      state.errorMessage = "";
      console.log("submitAlias.pending state");
    });
    builder.addCase(submitAlias.fulfilled, (state, action) => {
      const { alias, balance } = action.payload;

      if (alias.data.responseMessage === "Success" && alias.data.user) {
        onPuntersLogin(state, alias.data, true, balance);
      } else {
        state.errorMessage =
          "Saving punter alias was not successful, please try again.";
      }

      console.log("submitAlias.fulfilled state");
    });
    builder.addCase(submitAlias.rejected, (state) => {
      console.log("submitAlias.pending state");
      state.errorMessage =
        "Saving punter alias was not successful, please try again.";
    });

    builder.addCase(getLegacyAccessToken.fulfilled, (state, action) => {
      state.legacyAccessToken = action.payload.legacyAccessToken.data;
    });

    builder.addCase(puntersChallengeLogin.pending, (state) => {
      state.aliasRequired = false;
      state.isPending = true;
      console.log("submitAlias.pending state");
    });
    builder.addCase(puntersChallengeLogin.fulfilled, (state, action) => {
      const { puntersLoggedInData, balance } = action.payload;

      onPuntersLogin(state, puntersLoggedInData, true, balance);
    });
    builder.addCase(attemptPersistedLogin.fulfilled, (state, action) => {
      if (action.payload) {
        const { puntersLoggedInData, balance } = action.payload;

        onPuntersLogin(state, puntersLoggedInData, true, balance);
      }
      if (!state.hasCheckedLoggedIn) state.hasCheckedLoggedIn = true;
    });
    builder.addCase(attemptPersistedLogin.rejected, () => {
      console.error("attemptPersistedLogin.rejected state");
    });
  }
});

export const LoggedInSelector = createSelector(
  (state: RootState) => state.user,
  (data) => data.isLoggedIn
);

export const { logout, setUserName } = UserSlice.actions;

export default UserSlice.reducer;
