import styled from "styled-components";
import { forwardRef } from "react";
import { type IContainer, StyledContainer } from "./container";

export interface IFlexContainer extends IContainer {
  direction?: string;
  justifycontent?: string;
  rowgap?: string;
  columngap?: string;
  wrap?: string;
  alignitems?: string;
  aligncontent?: string;
  flow?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  borderBottom?: string;
  borderBottomLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
}

const StyledFlexContainer = styled(StyledContainer)<IFlexContainer>`
  display: -webkit-flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  flex-wrap: ${(props) => props.wrap ?? "nowrap"};
  flex-flow: ${(props) =>
    props.flow ?? `${props.direction === "column" ? "column" : "row"} nowrap`};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  align-items: ${(props) => props.alignitems ?? "normal"};
  align-content: ${(props) => props.aligncontent ?? "normal"};
  row-gap: ${(props) => props.rowgap ?? "0px"};
  column-gap: ${(props) => props.columngap ?? "0px"};
`;

const FlexContainer = forwardRef((props: IFlexContainer, ref) => (
  <StyledFlexContainer ref={ref} {...props} style={{ ...props.style }}>
    {props.children}
  </StyledFlexContainer>
));

export default FlexContainer;
