import { IInitialDataBetslip, SportTypes } from "../types/types";
import Betslip from "../utilities/BetslipInitClass";

/**
 * A config object that contains all the initial states for any sport that may be added to the project in the future.
 *
 */
const BetSlipSliceInitData: IInitialDataBetslip = {
  [SportTypes.HorseRacing]: {
    increments: [1, 5, 10, 50],
    event: {
      id: 0,
      name: "",
      date: ""
    },
    selections: [],
    bets: Betslip.BuildHorseRacingBetTypes(),
    totalStake: 0,
    totalReturn: 0,
    betStrikeResponse: null,
    allowBet: true,
    forcedSP: false,
    isFetchingBetslipData: false,
    betslipData: {
      bets: [],
      spBets: [],
      placeBets: []
    },
    limits: {
      firstTimerLimits: {
        single: 0,
        double: 0,
        treble: 0,
        quad: 0,
        multiple: 0
      },
      regularLimits: {
        single: 0,
        double: 0,
        treble: 0,
        quad: 0,
        multiple: 0
      }
    },
    punterBetAmount: 0,
    hasFetchedPunterBetAmount: false,
    headerSelectOddsToggle: false,
    isStrikingBet: false
  },
  [SportTypes.Soccer]: {
    increments: [1, 2, 3, 4],
    event: {
      id: 0,
      name: "",
      date: ""
    },
    selections: [],
    bets: Betslip.BuildSoccerBetTypes(),
    totalStake: 0,
    totalReturn: 0,
    betStrikeResponse: null,
    allowBet: true,
    forcedSP: false,
    isFetchingBetslipData: false,
    betslipData: {
      bets: [],
      spBets: [],
      placeBets: []
    },
    limits: {
      firstTimerLimits: {
        single: 0,
        double: 0,
        treble: 0,
        quad: 0,
        multiple: 0
      },
      regularLimits: {
        single: 0,
        double: 0,
        treble: 0,
        quad: 0,
        multiple: 0
      }
    },
    punterBetAmount: 0,
    hasFetchedPunterBetAmount: false,
    headerSelectOddsToggle: false,
    isStrikingBet: false
  }
};

export default BetSlipSliceInitData;
