/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

const configConst = (state: RootState) => state.config.data;

const PREFIX_KEY_TO_SAVE: string = "stats_";

export interface IStatsAllTimeData {
  totalSelections: number;
  totalWins: number;
  winPercentage: number;
  mostWinners: number;
}

export interface IStatsByRaceCourseTimelineData {
  raceCourse: number;
  raceCourseName: string;
  totalSelections: number;
  totalWins: number;
  winPercentage: number;
  mostWinners: number;
}

export interface IStatsMilestoneData {
  punterBestCourseAverage: number;
  punterBestCourseName: string;
  courseAverageAllPunters: number;
  jackpotWinners: number;
  highestPointsScoredByAllPunters: number;
  highestPointsScoredRacecourseName: string;
  highestPointsScoredMeetingDate: string;
  bestFinish: string;
  bestMeetingFinish: number;
  meetingDate: string;
  puntersOutPerformed: number;
  highestPointsScored: number;
  top10: number;
  top50: number;
  top500: number;
  top1000: number;
}

interface IStats {
  allTimeDataByTimeline: { [timelineId: number]: IStatsAllTimeData };
  raceCourseDataByTimeline: {
    [timelineId: number]: IStatsByRaceCourseTimelineData[];
  };
  milestoneDataByTimeline: { [timelineId: number]: IStatsMilestoneData };
  currentTimelineId: number;
  errorMessage: string;
  isOlderDataCleared: boolean;
}

const initialState: IStats = {
  allTimeDataByTimeline: {},
  raceCourseDataByTimeline: {},
  milestoneDataByTimeline: {},
  currentTimelineId: 1,
  errorMessage: "",
  isOlderDataCleared: false
};

interface IGetAllTimeStats {
  punterId: number;
  dateKey: number;
}

export const getStatsDataByTimeline: any = createAsyncThunk(
  "getStatsDataByTimeline",
  async ({ punterId, dateKey }: IGetAllTimeStats, { getState }) => {
    const config = configConst(getState() as RootState);

    const date = new Date();
    const savedData = localStorage.getItem(
      `${PREFIX_KEY_TO_SAVE}${punterId}_${date.getDate()}_${date.getMonth()}_${dateKey}`
    );

    const parsedSavedData = savedData ? JSON.parse(savedData) : null;

    const allTimeResponse = parsedSavedData
      ? null
      : await axios.get(
          `${config.puntersApi}/api/Stats/AllTimeStats/${punterId}/${dateKey}`
        );

    const statsByRaceCourseResponse = parsedSavedData
      ? null
      : await axios.get(
          `${config.puntersApi}/api/Stats/AllTimeStatsPerRaceCourse/${punterId}/${dateKey}`
        );

    const mileStonesResponse = parsedSavedData
      ? null
      : await axios.get(
          `${config.puntersApi}/api/Stats/Milestones/${punterId}/${dateKey}`
        );

    return {
      alltimeData: allTimeResponse,
      raceCoursesData: statsByRaceCourseResponse,
      milestonesData: mileStonesResponse,
      timelineId: dateKey,
      savedData: parsedSavedData,
      punterId
    };
  }
);

const StatsSlice = createSlice({
  name: "Stats",
  initialState,
  reducers: {
    setCurrentTimelineId: (state, action) => {
      state.currentTimelineId = action.payload;
    },
    clearOldSavedData: (state, action) => {
      if (state.isOlderDataCleared) return;

      const oldEntries = Object.entries(localStorage).filter(([key]) =>
        key.startsWith(PREFIX_KEY_TO_SAVE)
      );

      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth();

      oldEntries.forEach((entry) => {
        const key = entry[0];

        const keyItems = key.split("_");

        if (
          Number(keyItems[2]) !== day ||
          Number(keyItems[3]) !== month ||
          action.payload.punterId !== Number(keyItems[1])
        ) {
          localStorage.removeItem(key);
        }
      });

      state.isOlderDataCleared = true;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getStatsDataByTimeline.pending, (state) => {
      state.errorMessage = "";
    });
    builder.addCase(getStatsDataByTimeline.fulfilled, (state, action) => {
      const {
        alltimeData,
        raceCoursesData,
        milestonesData,
        timelineId,
        savedData,
        punterId
      } = action.payload;
      const {
        allTimeDataByTimeline,
        raceCourseDataByTimeline,
        milestoneDataByTimeline
      } = state;

      allTimeDataByTimeline[timelineId] =
        savedData?.allTimeDataByTimeline[timelineId] ?? alltimeData?.data.stats;
      raceCourseDataByTimeline[timelineId] =
        savedData?.raceCourseDataByTimeline[timelineId] ??
        raceCoursesData?.data.allTimeStatsPerRaceCourse;
      milestoneDataByTimeline[timelineId] =
        savedData?.milestoneDataByTimeline[timelineId] ??
        milestonesData?.data.punterStatsMilestones;

      if (!savedData) {
        const date = new Date();
        localStorage.setItem(
          `${PREFIX_KEY_TO_SAVE}${punterId}_${date.getDate()}_${date.getMonth()}_${timelineId}`,
          JSON.stringify(state)
        );
      }

      console.log(
        `getStatsPerRaceCourseByTimeline.fulfilled state ,returned data = ${action.payload}`
      );
    });
    builder.addCase(getStatsDataByTimeline.rejected, (state, action) => {
      state.errorMessage = action.error.message;
      console.log("getAllTimeStats.rejected state");
    });
  }
});

export const { setCurrentTimelineId, clearOldSavedData } = StatsSlice.actions;

export default StatsSlice.reducer;
