export const MENU_ITEMS_LOGGED_IN: Record<string, string> = {
  Home: "/",
  Leaderboard: "leaderboard",
  Results: "results",
  "Friends Challenge": "friendsChallenge",
  "Learn How to Play": "learntoplay",
  "Terms and Conditions": "termsandconditions",
  "My Stats": "mystats",
  FAQ: "faqpage",
  "PAIA Manual": "paia",
  stats: "stats"
};
export const MENU_ITEMS_LOGGED_OUT: Record<string, string> = {
  Home: "/",
  Leaderboard: "leaderboard",
  "Learn How to Play": "learntoplay",
  "Terms and Conditions": "termsandconditions",
  FAQ: "faqpage",
  "PAIA Manual": "paia"
};
export const RACE_SELECTION_CACHE: string = "selectedRaces";
export const RACE_SELECTION_CACHE_NEW: string = "selectedV3Races";

export const SELECTION_CUTOFF_TIME_IN_MINUTES: number = 5;

export const FAQ_IDENTIFIER_TEXT: string = "Frequently Asked Questions";

export const PAIA_IDENTIFIER_TEXT: string = "PAIA Manual";

export const TC_IDENTIFIER_TEXT = "Terms and Conditions";

export const TC_ERROR_MESSAGE =
  "Sorry, Terms and conditions data is not available at the moment.";

export const DIRECTIONS = {
  left: "left",
  right: "right",
  up: "up",
  down: "down"
};

export const STATS_DROP_DOWN_ITEMS_ITEMS = ["All time stats", "Current week"];
export const STATS_TIMELINES = ["All Time", "Year", "Month", "Week"];

export const DEVICE_SIZES = {
  MOBILE_SMALL: "320px",
  MOBILE_MEDIUM: "375px",
  MOBILE_LARGE: "480px",
  MOBILE_EXTRA_LARGE: "640px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_LARGE: "1440px",
  DESKTOP: "3000px"
};
