import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userSlice from "./features/userSlice";
import meetingsSlice from "./features/meetingsSlice";
import leaderBoardSlice from "./features/leaderboardSlice";
import pagesSlice from "./features/pagesSlice";
import betslipSlice from "./features/betslipSlice";
import configSlice from "./features/configSlice";
import newBetSlipSlice from "./features/newBetSlipSlice";
import resultsSlice from "./features/resultsSlice";
import friendsLeagueSlice from "./features/friendsLeague/friendsLeagueSlice";
import settingsSlice from "./features/settingsSlice";
import BetslipV3Slice from "./features/BetslipV3/slice/BetslipV3Slice";
import statsSlice from "./features/statsSlice";
import generalSlice from "./generalSlice";

const rootReducer = combineReducers({
  user: userSlice,
  genaral: generalSlice,
  meetings: meetingsSlice,
  leaderboard: leaderBoardSlice,
  results: resultsSlice,
  pages: pagesSlice,
  betslip: betslipSlice,
  config: configSlice,
  betslipNew: newBetSlipSlice,
  friendsLeague: friendsLeagueSlice,
  settings: settingsSlice,
  betslipv3: BetslipV3Slice,
  stats: statsSlice
});

const Store = configureStore({
  reducer: {
    user: userSlice,
    general: generalSlice,
    meetings: meetingsSlice,
    leaderboard: leaderBoardSlice,
    results: resultsSlice,
    pages: pagesSlice,
    betslip: betslipSlice,
    config: configSlice,
    betslipNew: newBetSlipSlice,
    friendsLeague: friendsLeagueSlice,
    settings: settingsSlice,
    betslipv3: BetslipV3Slice,
    stats: statsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof Store.getState>;
export default Store;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
};
